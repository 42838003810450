<!--
Control bar with search input fields
-->
<template>
    <div class="container w-100 searchDashboard small">
      <div class="d-flex searchDashboardMainArea">
        <div class="d-flex searchDashboardMainAreaInputs">
          <div>
            <div class="searchDashboardLabel">suche im Titel</div>
            <input placeholder="suche im Titel" v-model="searchStringTitle" @keyup="searchKeyUp" list="titleList" :title="titleTooltip" class="w-100"/>
            <datalist id="titleList">
                <option v-for="item in dataListeForTitles" :value="item.title" />
            </datalist>
          </div>
          <div>
            <div class="searchDashboardLabel">suche im Volltext</div>
            <input placeholder="suche im Volltext" v-model="searchStringFulltext" @keyup="searchKeyUp" class="w-100"/>
          </div>
        </div>
        <div class="searchDashboardSearch">
          <button @click="search" class="btn btn-secondary float-end">suchen</button>
        </div>
      </div>

      <div class="searchDashboardAdvancedToggler" @click="advancedAreaExpanded=!advancedAreaExpanded" title="Datum, Reihenfolge, etc.">
          <div v-if="!advancedAreaExpanded">&#x25BA;</div>
          <div v-if="advancedAreaExpanded">&#x25E2;</div>
          <div class="flex-grow-1">Erweiterte Sucheinstellungen</div>
          <div class="searchDashboardSearchMode">Suche in
              <span v-if="searchMode=='event'">Tagungen</span>
              <span v-if="searchMode=='text'">Texten</span>
          </div>
      </div>
      <Transition>
         <div v-if="advancedAreaExpanded" class="searchDashboardAdvancedArea">
            <div>
                <span class="searchDashboardLabel">Datum</span>
                <cmp-ctrl-date-picker :fromDate="fromDate" :toDate="toDate" min="1910-01-01"
                           max="1969-12-31" v-on:datechanged="dateRangeChanged"
                           @enter="search"></cmp-ctrl-date-picker>
            </div>
            <div class="d-flex">
              <span class="searchDashboardLabel">Stichworte</span>
              <multiselect v-model="selectedKeywords" :options="allKeywords" :multiple="true" :close-on-select="false"
                 placeholder="Stichworte" label="keyword" track-by="id" class="small"></multiselect>
            </div>

            <div class="d-flex">
                <div class="radio text-left searchDashboardSearchAlgorithm border d-flex">
                    <div>
                        <span class="searchDashboardHelp" title="help" @click="showHelp('searchType')">&#x1F6C8;</span>
                    </div><div>
                        <input type="radio" id="searchDashboardSearchAlgorithmExact" v-bind:checked="searchAlgorithm=='exact'" value="exact" v-on:input="setSearchAlgorithm" name="searchDashboardSearchAlgorithm">
                        <label for="searchDashboardSearchAlgorithm">exakt</label>
                    </div><div>
                        <input type="radio" id="searchDashboardSearchAlgorithmIntelligent" v-bind:checked="searchAlgorithm=='intelligent'" value="intelligent" v-on:input="setSearchAlgorithm" name="searchDashboardSearchAlgorithm">
                        <label for="searchDashboardSearchAlgorithm">intelligent</label>
                    </div>
                </div>
                <div v-dir-permission="'admin'"  class="searchDashboardExtendedSearchSwich border">
                    <span class="searchDashboardLabel">erweiterte Suche</span>
                    <cmp-ctrl-slider-checkbox v-model="showExtendedSearch"></cmp-ctrl-slider-checkbox>

                </div>
                <div class="searchDashboardSearchOrder" v-if="searchAlgorithm=='intelligent'">
                  <select title="Reihenfolge der Suchergebnisse" v-model="searchOrder">
                    <option value="relevance">Sortiert nach Relevanz</option>
                    <option value="dateAsc">Sortiert nach Datum</option>
                    <option value="dateDesc">Sortiert nach Datum invers</option>
                  </select>
                </div>
            </div>
            <div class="searchDashboardSearchFrame d-flex">
              <div class="flex-grow-1">
                <span v-if="showExtendedSearch" class="searchDashboardLabel">erweiterte Suche</span>
                <input v-if="showExtendedSearch" placeholder="erweiterte Suche" id="searchDashboardSearchExtendedInput" v-model="searchStringExtended" @keyup="searchKeyUp" />
              </div>
              <div id="searchDashboardEditEventIcon">
                  <span v-dir-permission="'g_EDITORS'" v-on:click="addRecord">&#x2795;</span>
                  <span v-dir-permission="'g_EDITORS'" v-on:click="addRecord">Event</span>
              </div>
            </div>
         </div>
      </Transition>
    </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
    .searchDashboard {
        background-color: #ffffff;
        margin-bottom: 5px;
    }
    .searchDashboardMainArea {
        text-align: left;
        margin-bottom:8px;
    }
    .searchDashboardMainArea div {
        margin-right: 30px;
    }
    .searchDashboardMainAreaInputs {
        flex-grow: 1;
    }
    .searchDashboardMainAreaInputs div {
        width: 50%
    }
    .searchDashboardLabel {
        font-size:0.7em;
        font-weight:700;
        margin-right:10px;
    }
    div.searchDashboardAdvancedToggler {
        display: flex;
        border: 1px solid #333333;
        background-color:#dddddd;
        text-align: left;
        cursor:pointer;
        font-size:0.8em;
    }
    div.searchDashboardAdvancedArea {
        border: 1px solid #333333;
        border-top: 0px;
        text-align: left;
        padding: 10px;
    }
    div.searchDashboardAdvancedArea div {
        margin-bottom: 10px;
    }
    .searchDashboardSearchFrame {
        width:100%;
        height:23px;
    }
    .searchDashboardSearchFrame span {
        height:23px;
    }
    .searchDashboardSearchFrame input {
        height:23px;
    }
    #searchDashboardSearchExtendedInput {
    }
    #searchDashboardEditEventIcon {
    }
    #searchDashboardEditEventIcon span {
        cursor: pointer;
    }
    .searchDashboardKeywords {
        margin-left:0px;
    }
    .searchDashboardSearchOrder {
        margin-left:30px;
    }
    .searchDashboardSearchOrder select {
        color: #555555;
    }
    .searchDashboardSearch {
        margin-left:30px;
    }
    span.searchDashboardSearchMode {
        font-size:0.8em;
        white-space: nowrap;
    }
    div.searchDashboardSearchAlgorithm {
        font-size:0.8em;
        margin-left: 8px;
    }
    div.searchDashboardSearchAlgorithm div{
        margin: 5px;
        padding: 2px;
    }
    div.searchDashboardSearchAlgorithm input {
        accent-color: #777777;
    }
    span.multiselect__tag {
        background-color:#007bff;
    }
    span.multiselect__option--highlight {
        background-color:#007bff;
    }
    div.searchDashboardExtendedSearchSwich {
        margin: 5px;
        padding: 2px;
    }
    span.searchDashboardHelp {
        margin-left: 5px;
        cursor: pointer;
        font-size: 130%;
    }
</style>
<script>
import CmpCtrlDatePicker from './CmpCtrlDatePicker.vue';
import CmpCtrlSliderCheckbox from '../../controls/CmpCtrlSliderCheckbox';
import Multiselect from 'vue-multiselect'; // https://vue-multiselect.js.org/#sub-getting-started
import SrvServerAccess from '../../global/SrvServerAccess';
import SrvMessages from '../../global/SrvMessages';
import DirPermission from '../../controls/DirPermission';

export default {
    props: [
        'preset'   // {fromDate:...,toDate:...,...]  um von aussen Werte zu setzen
        // emit  search        (payload: Suchdaten) nach Druecken Search
        // emit  add-event     nach Druecken auf +
        // emit  show-help     (payload: Help-URL) nach Druecken auf Hilfebutton
    ],
    data: function() {
        return {
            advancedAreaExpanded: false,
            fromDate: '1910-01-01',
            toDate: '1968-12-31',
            searchStringFulltext: '',
            searchStringTitle: '',
            searchStringExtended: '',
            searchAlgorithm: 'intelligent', // // one of 'exact' or 'intelligent'
            selectedKeywords: [],
            allKeywords: [], //  [{keyword:...,id:...}]
            dataListeForTitles: [], // [{event_id:...,title:...}]
            titleTooltip: '',
            limit: 10,          // hits per page
            showExtendedSearch: false,  // flag for extended search textfield
            searchOrder: 'relevance'  // order of results: one of relevance, dateAsc, dateDesc
        }
    },
    components: {
        cmpCtrlDatePicker: CmpCtrlDatePicker,
        cmpCtrlSliderCheckbox: CmpCtrlSliderCheckbox,
        multiselect: Multiselect
    },
    computed: {
        searchMode: function() { // one of 'event' or 'text'
            return this.searchStringFulltext ? 'text' : 'event';
        }
    },
    watch: {
        searchStringTitle: function() {
            this.loadTitles();
        },
        preset: function(newPreset) {
            this.fromDate = newPreset.fromDate ? newPreset.fromDate : '';
            this.toDate = newPreset.toDate ? newPreset.toDate : '';
            this.searchStringFulltext = newPreset.searchStringFulltext ? newPreset.searchStringFulltext : '';
            this.searchStringTitle = newPreset.searchStringTitle ? newPreset.searchStringTitle : '';
            this.searchStringExtended = newPreset.searchStringExtended ? newPreset.searchStringExtended : '';
            this.searchAlgorithm = newPreset.searchAlgorithm ? newPreset.searchAlgorithm : '';
/*
            this.selectedKeywords = newPreset.selectedKeywords ? newPreset.selectedKeywords : [];
            this.allKeywords = newPreset.allKeywords ? newPreset.allKeywords : [];
            this.dataListeForTitles = newPreset.dataListeForTitles ? newPreset.dataListeForTitles : [];
            this.titleTooltip = newPreset.titleTooltip ? newPreset.titleTooltip : '';
            this.limit = newPreset.limit ? newPreset.limit : '';
*/
        }
    },
mounted: function() {
this.loadKeywords();
},
    methods: {
        search: function() {
            const keywords = [];
            for (let i=0; i<this.selectedKeywords.length; i++)
                keywords.push(this.selectedKeywords[i].id);
            const searchData = {
                fromDate: this.fromDate,
                toDate: this.toDate,
                searchStringFulltext: this.searchStringFulltext,
                searchStringTitle: this.searchStringTitle,
                searchStringExtended: this.searchStringExtended,
                keywordIds: keywords,
                searchAlgorithm: this.searchAlgorithm,
                searchOrder: this.searchOrder,
                limit: (this.limit && !isNaN(this.limit)) ? this.limit : 10
            };
            window.history.pushState(searchData, '', null); // make an own state for history (browser back button, no change of url)
            this.$emit('search', searchData);
        },
        searchKeyUp: function(evt) {
            if (evt.key=='Enter')
                this.search();
        },
        fromDateChanged: function() {
            if (this.fromDate>this.toDate)
                this.toDate = this.fromDate;
        },
        toDateChanged: function() {
            if (this.fromDate>this.toDate)
                this.fromDate = this.toDate;
        },
        dateRangeChanged: function(fromTo) {
            this.fromDate = fromTo.from;
            this.toDate   = fromTo.to;
        },
        setSearchAlgorithm: function(evt) {
             this.searchAlgorithm = evt.target.value;
        },
        loadKeywords: function() {
            const that = this;
            SrvServerAccess.request('POST','/api/GetKeywords', {category: 'all'}).then(
                function(serverData) {
                    // Format of serverData
                    // {data:{
                    //   {keywords:[
                    //     {id:...,
                    //      key_id:...,
                    //      key_keyword:...,
                    //      key_category:...,
                    //      key_lang:...
                    //   },{...}]}
                    // } }
                    const keywords = serverData.data.keywords;
                    for (let i=0; i<keywords.length; i++)
                        that.allKeywords.push({keyword: keywords[i].key_keyword, id:keywords[i].key_id});
                },
                function(error) {
                    // maybe SessionToken is got invalid
                    if (error && error.response && (error.response.status==401))
alert('TODO CmpSearchDashboard - Session abgelaufen: das sollte im Router abgefangen werden');
                    // other errors, i.e. no rights
                    else if (error && error.response && error.response.data) {
                        const errortext = error.response.data.error ? error.response.data.error : 'something got wrong';
                        SrvMessages.error(errortext);
                    }
                    that.isLoading = false;
                }
            );
        },
        loadTitles: function() {
            if (this.searchStringTitle.length<3) {
                this.dataListeForTitles = [];
                this.titleTooltip = '';
                return;
            }
            const that = this;
            SrvServerAccess.request('POST','/api/titleGet', {s_title: this.searchStringTitle},true).then(
                function(serverData) {
                    // Format of serverData
                    // {data:{count: 33, titles: [{event_id:...,title:...},{...}]}}
                    that.dataListeForTitles = serverData.data.titles;
                    that.titleTooltip = serverData.data.titles.length+' von '+serverData.data.count+' Titeln';
                },
                function(error) {
                    that.dataListeForTitles = [];
                    that.titleTooltip = '';
                    // if the user has no rights, we get also an error here,
                    // but we do not want to confuse the user with this information
                    if (error && error.response && error.response.data) {
                        const errortext = error.response.data.error ? error.response.data.error : 'something got wrong';
                        if (errortext.indexOf('no permission')>=0)
                            that.showNoRightsInfo = true;
                        else
                            SrvMessages.error(errortext);
                    }
                }
            );
        },
        addRecord: function() {
            this.$emit('add-event');
        },
        showHelp: function(helpKey) {
            const MAP = {
                searchType: 'https://docs.google.com/document/d/1W0DUKIL15wKRmNNtaRN0N8yWnwNeaBOl/edit#bookmark=id.44gt6tfjkqcq'
            };
            const helpUrl = MAP[helpKey];
            if (helpUrl) {
                this.$emit('show-help', helpUrl);
            }
        }
    },
    directives: {
        DirPermission: DirPermission
    }
}
</script>
