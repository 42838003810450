<!--
View for the User Data
-->
<template>
  <div class="user">
    <div class="container">
      <div  class="row">
          <div  class="col-md-12">
              <h1>Benutzerdaten</h1>
          </div>
      </div>
      <div class="row">
        <div class="col-6 text-left">
          <span class="userKey">ID:</span><span class="userValue">{{localUserData.user.id}}</span><br/>
          <span class="userKey">Username:</span><span class="userValue">{{localUserData.user.username}}</span><br/>
          <span class="userKey">Email:</span><span class="userValue">{{localUserData.user.email}}</span><br/>
          <span class="userKey">Email verifiziert:</span>
          <span v-if="localUserData.user.email_verified_at"  class="userValue">{{localUserData.user.email_verified_at}}</span>
          <button v-if="!localUserData.user.email_verified_at" @click="emailVerification" class="userButton btn-primary btn-sm">Email Verification necessary</button>
          <br/>
          <span class="userKey">Seit:</span><span class="userValue">{{createDate}}</span><br/>
          <span class="userKey">verfügbare Ereignisse:</span><span class="userValue">{{localUserData.accessibleEvents}}</span><br/>
          <span class="userKey">verfügbare Medien:</span><span class="userValue">{{localUserData.accessibleMediums}}</span><br/>
          <span class="userKey">verfügbare Kommentare:</span><span class="userValue">{{localUserData.accessibleComments}}</span><br/>
<!--
          <span class="userKey">Permissions</span><span class="userValue">{{localUserData.permissions}}</span><br/>
-->
          <div>
              <span class="userKey">Rollen:</span><br/>
              <div v-for="(role) in localUserData.roles">{{role}}</div>
          </div>
<!--
          <div v-for="(container, containerKey) in localUserData">
            <h2>{{containerKey}}</h2>
            <div v-for="(value, key) in container">
              <b>{{key}}:</b>&nbsp;<i>{{value}}</i>
            </div>      
          </div>
-->
        </div>
        <div class="col-6 text-left">
          <span class="userKey">Vorname:</span><input v-model="localUserData.settings.FirstName" class="userInput"/><br/>
          <span class="userKey">Familienname:</span><input v-model="localUserData.settings.LastName" class="userInput"/><br/>
          <span class="userKey">voller Name:</span><input v-model="localUserData.settings.FullName" class="userInput"/><br/>
          <span class="userKey">Systemsprache:</span>
          <select class="userKey" v-model="localUserData.settings.LangUI">
            <option>deu</option>
            <option>eng</option>
            <option>spa</option>
          </select>
          (zur Zeit geht nur deutsch)
          <br/>
          <span class="userKey">Sprachen für Suche:</span>
          <span class="userSettingsLanguageIcon" v-for="(record) in LANGUAGES">
            <ctrl-language-icon
                :lang = "record.lang"
                :clickable = "true"
                :semi-transparent = "searchLanguages.indexOf(record.lang)<0"
                :title="record.title"
                v-on:click="toggleSearchLanguage(record.lang)"
            ></ctrl-language-icon>
          </span><br/>
          <span class="userKey">angezeigte Sprachen:</span>
          <span class="userSettingsLanguageIcon" v-for="(record) in LANGUAGES">
            <ctrl-language-icon
              :lang = "record.lang"
              :clickable = "true"
              :semi-transparent = "showLanguages.indexOf(record.lang)<0"
              :title="record.title"
              v-on:click="toggleShowLanguage(record.lang)"
            ></ctrl-language-icon>
          </span><br/>
          <span class="userKey">Max. Treffer pro Sprache:</span><input v-model="localUserData.settings.LangShowLimit" class="userInput"/><br/>
          <span class="userKey">Max. Treffer pro Seite:</span><input v-model="localUserData.settings.ResultsPerPage" class="userInput"/><br/>
          <span class="userKey">Nur Default Medien anzeigen</span><input type="checkbox" v-model="localUserData.settings.DefaultResultsOnly" class="userInput"/><br/>
          <button class="btn-primary" @click="save">Speichern</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
  span.userKey {
    font-weight: 700;
    font-size: 0.8rem;
  }
  span.userValue {
    margin-left: 10px;
    font-size: 0.8rem;
  }
  .userInput {
    margin-left: 10px;
    font-size: 0.8rem;
  }
  button.userButton {
    margin-left: 8px;
  }
  span.userSettingsLanguageIcon {
    margin-left: 5px;
  }
</style>

<script>
import SrvServerAccess from '../global/SrvServerAccess';
import SrvAuthentification from '../global/SrvAuthentification';
import SrvMessages from '../global/SrvMessages';
import CtrlLanguageIcon from '@/controls/CtrlLanguageIcon.vue';

export default {
    data: function() {
        return  {
            localUserData: {...SrvAuthentification.getUserdata()},
            LANGUAGES: [
                {lang: 'deu', title: 'deutsch'},
                {lang: 'eng', title: 'englisch'},
                {lang: 'hun', title: 'ungarisch'},
                {lang: 'spa', title: 'spanisch'}
            ]
        };
    },
    computed: {
        createDate: function() {
            // TODO use a helper function
            return this.localUserData.user.created_at.substr(8,2) + '.'
                 + this.localUserData.user.created_at.substr(5,2) + '.'
                 + this.localUserData.user.created_at.substr(0,4);
        },
        searchLanguages: function() {
            return this.localUserData.settings.LangSearch
                ? this.localUserData.settings.LangSearch.split(',') : [];
        },
        showLanguages: function() {
            return this.localUserData.settings.LangShow
                ? this.localUserData.settings.LangShow.split(',') : [];
        }
    },
    created: function() {
        /*
        SrvServerAccess.request('GET', '/me', {}).then(
            function(fullServerData) {
            },
            function(error) {
                // maybe SessionToken is got invalid
                if (error && error.response && (error.response.status==401))
                    globalFunctions.logout();
                // other errors, i.e. no rights
                else if (error && error.response && error.response.data) {
                    const errortext = error.response.data.error ? error.response.data.error : 'something got wrong';
                    SrvMessages.error(errortext);
                }
                that.isLoading = false;
            }
        );
        */
    },
    methods: {    
        save:function() {
            SrvServerAccess.request('POST', '/api/updateUserSettings', {
                FirstName:this.localUserData.settings.FirstName,
                LastName:this.localUserData.settings.LastName,
                FullName:this.localUserData.settings.FullName,
                LangUI:this.localUserData.settings.LangUI,
                LangSearch:this.localUserData.settings.LangSearch,
                LangShow:this.localUserData.settings.LangShow,
                LangShowLimit:this.localUserData.settings.LangShowLimit,
                ResultsPerPage:this.localUserData.settings.ResultsPerPage,
                DefaultResultsOnly:this.localUserData.settings.DefaultResultsOnly
            }).then(
                function(serverResponse) {
                    SrvMessages.info(serverResponse.data.message);
                }
            );
        },
        emailVerification: function() {
            SrvServerAccess.request('POST', '/api/emailVerificationSend').then(
                function(serverResponse) {
                    SrvMessages.info('Please check you emails and click to the verification link there.');
                }
            );
        },
        toggleSearchLanguage: function(lang) {
            let newArray = [...this.searchLanguages];
            if (this.searchLanguages.indexOf(lang)<0)
                newArray.push(lang);
            else
                newArray = newArray.filter(function(item) {return item != lang});
            this.localUserData.settings.LangSearch = newArray.join(',');
        },
        toggleShowLanguage: function(lang) {
            let newArray = [...this.showLanguages];
            if (this.showLanguages.indexOf(lang)<0)
                newArray.push(lang);
            else
                newArray = newArray.filter(function(item) {return item != lang});
            this.localUserData.settings.LangShow = newArray.join(',');
        }
    },
    components: {
        CtrlLanguageIcon: CtrlLanguageIcon
    }
}
</script>
