<!--
Displays one comment
-->
<template>
  <div class="comment">
    <div>
        <span>&#x1F5E8;</span>
        <span class="commentTitle" v-text="cachedComment.title"></span>
        <span class="commentIds">Event-Id: {{cachedComment.eventId}} Comment-Id: {{cachedComment.commentId}}</span>
    </div>
    <div class="commentText" v-text="cachedComment.text"></div>
  </div>    
</template>
<style>
    div.comment {
        text-align:left;
        margin-bottom: 30px;
    }
    span.commentTitle {
        font-weight:700;
        font-size: 1.2em;
    }
    span.commentIds {
        margin-left: 20px;
        font-size: 0.7em;
    }
    div.commentText {
        white-space: pre-wrap;
    }
</style>
<script>
import SrvServerAccess from '../global/SrvServerAccess';

export default {
    props: [
        'event-id',   // ID of the event, the comment belongs to
        'comment-id', // ID of the comment
        'comment'     // alternative: if the ready-loaded comment-record ist given the two ids are ignored
    ],
    // emit  open-version(eventId, versionId)
    // emit  open-text(mediumId)
    data: function() {
        return  {
            cachedComment: {} // {comment_id:..., comment_text..., keywords:[{id:..., key_keyword: 'MyKeyword'}], ...}
        };
    },
    created: function() {
        this.loadData();
    },
    watch: {
        eventId: function(newEventId) {
            this.loadData();
        },
        commentId: function(newCommentId) {
            this.loadData();
        },
        comment: function(newCommentId) {
            this.loadData();
        }
    },
    methods: {
        loadData: async function() {
            if (this.comment) { //  data record ist given
                this.cachedComment = {
                    text: comment.comment_text,
                    title: 'Kommentar '+i++,
                    commentId: comment.pivot.comment_id,
                    eventId: comment.pivot.event_id
                };
            }
            // ids are given, we must load the record
            this.cachedComment = {};
            if (!this.eventId || !this.commentId)
                return;
            try {
                const serverData = await SrvServerAccess.request('POST', '/api/eventGetComments', {event_id: this.eventId});
                // Format of serverData:
                // {data:[{comment_id:..., comment_text..., keywords:[{id:..., key_keyword: 'MyKeyword'}], ...}]}
                let i = 1;
                const that = this;
                serverData.data.comments.forEach(function(commentRecord) {
                    if (that.commentId != commentRecord.pivot.comment_id)
                        return;
                    that.cachedComment = {
                        text: commentRecord.comment_text,
                        title: 'Kommentar '+i++,
                        commentId: commentRecord.pivot.comment_id,
                        eventId: commentRecord.pivot.event_id
                    };
                });
            } catch(error) {
console.error(error);
            }
        },
    },
    components: {
//        CmpCtrlEvtTranslation: CmpCtrlEvtTranslation,
//        CtrlTree: CtrlTree
    }
}

</script>
