<!--
Wrapper for CmpCtrlComment to be used as RouterView. It shows one comment.
-->
<template>
  <div class="comment">
    <cmp-ctrl-comment
      :event-id="eventId"
      :comment-id="commentId"
    ></cmp-ctrl-comment>
  </div>    
</template>
<style>
</style>
<script>
import CmpCtrlComment from '@/controls/CmpCtrlComment.vue';
export default {
    data: function() {
        return  {
            eventId: '',
            commentId: ''
        };
    },
    created: function() {
        const idEventAndComment = this.$route.params.idEventAndComment.length ? this.$route.params.idEventAndComment.substr(1) : '';
        const parts = idEventAndComment.split('-');
        this.eventId = parts.shift();
        this.commentId = parts.shift();
    },
    components: {
        CmpCtrlComment: CmpCtrlComment
    }
}
</script>
