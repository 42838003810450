<template>
    <div class="search container">
        <cmp-search-dashboard
            :preset="preset4dashboard"
            @search="search"
            v-bind:style="{display:showOverlay?'none':''}"
            @add-event="addEvent"
            @show-help="showHelp"
        />
        <div v-if="searchStatus=='NO_RIGHTS'" class="searchNoRightsInfo">
            <b>Die Suche wird nicht erfolgreich sein, da Ihnen die notwendigen Berechtigungen fehlen.</b><br/>
            Bitte sprechen Sie mit dem System-Administrator.
        </div>
        <div v-if="searchStatus=='INIT'" class="searchNoRightsInfo">
            verfügbare Ereignisse: {{userData.accessibleEvents}}<br/>
            verfügbare Medien: {{userData.accessibleMediums}}<br/>
            verfügbare Kommentare: {{userData.accessibleComments}}
        </div>
        <div v-if="searchStatus=='NO_RESULTS'" class="searchNoRightsInfo">
            <b>Keine Suchergebnisse</b><br/>
            Versuchen Sie es bitte mit anderen Suchbegriffen.
        </div>
        <div class="searchResults" v-bind:style="{display:showOverlay?'none':''}">
            <pagination v-bind:class="{paginationLoading: isLoading, paginationNotLoading: !isLoading}"
                :data="data4pagination" 
                :limit="9" 
                @pagination-change-page="setPage"
            >
            </pagination>
            <div v-if="countResultsString" class="searchResultsCount">{{countResultsString}}</div>
            <cmp-search-result-block
                v-if="!isLoading"
                v-for="item,idx in list"
                v-bind:record="item"
                v-bind:index="idx"
                @open-medium="openMediumOverlay"
                @open-version="openVersionOverlay"
                @open-comments="openCommentsOverlay"
                @edit-event="openDanielView($event, 'EventEdit')"
                @add-version="openDanielView($event, 'VersionAdd')"
                @edit-medium="openDanielView($event, 'MediumEdit')"
                @edit-translation="openDanielView($event, 'TranslationEdit')"
                @add-translation="openDanielView($event, 'TranslationAdd')"
            ></cmp-search-result-block>
<!-- item.event_id -->
            <div v-if="isLoading">
                <img class="eventTextLoading" src="../../assets/kentenichWait.gif"><br/>
                ... Suche läuft</div>
            <pagination v-if="!isLoading && list.length>3" v-bind:class="{paginationLoading: isLoading, paginationNotLoading: !isLoading}"
                :data="data4pagination" 
                :limit="9" 
                @pagination-change-page="setPage"
            >
            </pagination>
        </div>
        <cmp-ctrl-overlay-for-nested-data 
            :version-id="versionID4Overlay"
            :event-id="versionEventID4Overlay"
            :medium-id="mediumId4Overlay"
            :highlighted="highlighted"
            :show-link-button="true"
            @open-medium="openMediumOverlay"
            :type="overlayType"
            @close="closeOverlay"
            @edit-version="function($evtVersData) { openDanielView($evtVersData,'VersionEdit')}"
            @edit-medium="function($mediumId) { openDanielView($mediumId,'MediumEdit')}"
            @add-medium="function($evtVersData) { openDanielView($evtVersData,'MediumAdd')}"
        ></cmp-ctrl-overlay-for-nested-data>
<!--             :help-url="helpUrl4Overlay"  -->

        <cmp-ctrl-overlay-for-daniels-views
            :eventId="danielEventId"
            :versionId="danielVersionId"
            :mediumId="danielMediumId"
            :translationId="danielTranslationId"
            :defaultLang="danielDefaultLang"
            :visibleView="danielVisibleView"
            @close="closeDanielOverlay"
        ></cmp-ctrl-overlay-for-daniels-views>
    </div>
</template>
<style>
    .search {
    }
    .searchResults {
        background-color: #cccccc;
    }
    .searchResultsCount {
        font-size: 1em;
        font-weight:700;
        text-align: left;
        padding-bottom: 8px;
    }
    .paginationLoading a {
        color: #cccccc;
        background-color: #cccccc;
        cursor: default;
    }
    .paginationNotLoading a {
        color: #333333;
        background-color: #cccccc;
        cursor: pointer;
    }
    .searchOverlay {
        position: absolute;
        top:58px;
        bottom:0px;
        left:0px;
        right:0px;
        background-color: #dddddd;
    }
    .searchOverlayCloser {
        position: absolute;
        top:10px;
        right:10px;
        cursor: pointer;
        font-weight:700;
    }
    .searchNoRightsInfo {
        color: #993333;
    }
</style>
<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld'

import CmpSearchDashboard from './CmpSearchDashboard';
import SrvServerAccess from '../../global/SrvServerAccess';
import CmpSearchResultBlock from './CmpSearchResultBlock';
import LaravelVuePagination from 'laravel-vue-pagination'
import SrvMessages from '../../global/SrvMessages';
import SrvAuthentification from '../../global/SrvAuthentification';
import CmpCtrlMedium from '@/controls/CmpCtrlMedium';
import CmpCtrlEvent from '@/controls/CmpCtrlEvent';
import CmpCtrlVersion from '@/controls/CmpCtrlVersion';
import CmpCtrlOverlayForNestedData from '@/controls/CmpCtrlOverlayForNestedData';
import CmpCtrlOverlayForDanielsViews from '@/controls/CmpCtrlOverlayForDanielsViews';

export default {
    data: function() {
        return  {
            countResultsString: '',
            isLoading: false,
            list: [],                    // if full text search is given:
                                         // [{ event: {
                                         //       event_accuracy:...,
                                         //       event_duration:...,
                                         //       event_end_date:...,
                                         //       event_id:...,
                                         //       event_start_date:...  }
                                         //   medium_id:...,
                                         //   medium_event_id:...,
                                         //   medium_title:...,
                                         //   medium_lang:...,
                                         //   highlighted:snippetString,   (only for ES-Search)
                                         //   version: {
                                         //       version_id:...,
                                         //       version_parent_id:...,
                                         //       version_title:...,
                                         //       version_lang:...  }
                                         // }]
                                         //
                                         // only with title search:
                                         // [{ event_accuracy:...,
                                         //   event_duration:...,
                                         //   event_end_date:...,
                                         //   event_id:...,
                                         //   event_start_date:...  }
                                         // }]
            data4pagination: {},
            preset4dashboard: {},
            currentPage: 1,
            searchData: {},
            scrollPosition: 0,
            mediumId4Overlay: '',
            eventID4Overlay: '',
            versionID4Overlay: '',
            versionEventID4Overlay: '',
//            helpUrl4Overlay: '',
            highlighted: '',
            overlayType: '',
            danielEventId: '',
            danielVersionId: '',
            danielMediumId: '',
            danielTranslationId: '',
            danielDefaultLang: '',
            danielVisibleView:'',
            searchStatus:'INIT', // INIT, NO_RIGHTS, NO_RESULTS, RESULTS
            userData: SrvAuthentification.getUserdata()
        };
    },
    computed: {
        showOverlay: function() {
            return !!this.overlayType;
        }
    },
    mounted: function() {
        window.addEventListener('popstate', this.handlePopState);
        if (!this.userData.accessibleEvents && !this.userData.accessibleMediums)
            this.searchStatus = 'NO_RIGHTS';
    },
    beforeDestroy() {
        window.removeEventListener('popstate', this.handlePopState);
    },
    methods: {
        handlePopState:  function(event) {
            const stateToBeRestored = event.state;
            if (! stateToBeRestored)
                return;
            this.preset4dashboard = stateToBeRestored;
            // if a overlay is open, is should be closed
            this.closeOverlay();
        },
        search: function(searchData) {
            this.searchData = searchData;
            this.currentPage = 1;
            if (searchData.searchStringExtended && (searchData.searchStringExtended.indexOf('$ES:')==0))
                this.loadResultsES();
            else
                this.loadResults();
        },  
        loadResults: function() {
//            const userData = SrvAuthentification.getUserdata();
const userData = this.userData;
            const startDate = this.searchData.fromDate;
            const endDate   = this.searchData.toDate;
            const data = {
                s_modus: (this.searchData.searchAlgorithm=='intelligent') ? 'intelligent' : 'exakt',
                s_text: this.searchData.searchStringFulltext,
                s_title: this.searchData.searchStringTitle,
                s_extended: this.searchData.searchStringExtended,
                s_orderBy: this.searchData.searchOrder,
                from: startDate,
                to: endDate,
                page: this.currentPage,
                tags: this.searchData.keywordIds,
                LangSearch: userData.settings.LangSearch,
                LangShow: userData.settings.LangShow,
                LangShowLimit: userData.settings.LangShowLimit,
                ResultsPerPage: userData.settings.ResultsPerPage,
                DefaultResultsOnly: userData.settings.DefaultResultsOnly
            };
            const resultsAreEvents = ! this.searchData.searchStringFulltext;
            let apiFunction = resultsAreEvents
             ?  '/api/searchE'  // user looking for event titles with this string
             : '/api/searchT';  // user want a fulltext search
            if (!resultsAreEvents && (this.searchData.searchAlgorithm=='intelligent'))
                apiFunction = '/api/searchTF';
            this.isLoading = true;
            this.countResultsString = '';
            const that = this;
            SrvServerAccess.request('POST',apiFunction, data, true).then(
                function(fullServerData) {
                    // Format of fullServerData:
                    // {data:{
                    //    events:{                      or texts:
                    //        total: 38,
                    //        last_page: 4,
                    //        data:[{event_id:...,event_file:...,...},{...},{...}]
                    // }  }
                    const serverData = fullServerData.data;
                    that.processServerData(serverData);
                },
                function(error) {
                    that.isLoading = false;
                    if (error && error.response && error.response.data) {
                        // other no rights
                        const errortext = error.response.data.error ? error.response.data.error : 'something got wrong';
                        if (errortext.indexOf('permission')>=0) // you do not have the permission "jk_view" that is necessary for this acction!
                            that.searchStatus = 'NO_RIGHTS';
                        else
                            SrvMessages.error(errortext);
                    }
                    // maybe SessionToken is got invalid
                    else if (error && error.response && (error.response.status==401)) {
                        SrvAuthentification.logout();
                        that.$router.push('/login/after-logout').catch(function(){});
                    }

                }
            );
        },
        loadResultsES: function() {
            const that = this;
            const searchString = this.searchData.searchStringExtended.substr(4);
            this.searchData.searchStringExtended = '';
            SrvServerAccess.requestES(searchString).then(
                function(result) {
                    that.list = [];
                    result.data.hits.hits.forEach(function(hit) { // {highlight:{text:[block],_id:'MED_12232'
                        that.list.push({
                            event: {event_id: hit._source.eventId },
                            medium_id:  hit._id.substr(4),
                            medium_text_short: hit.highlight.text.join('<br/>'),
                            highlighted: hit.highlight.text
                        });
                    });
                    that.searchData.searchStringFulltext = searchString;
                },
                function(error) {
                    console.log('Elasticsearch ERROR:');
                    console.log(error);
                }
            );
        },
        processServerData: function(serverData) {
            // Format of serverData:
            // {events:{                      or texts:
            //        total: 38,
            //        last_page: 4,
            //        data:[{event_id:...,event_file:...,...},{...},{...}]
            // }
            const resultsAreEvents = ! this.searchData.searchStringFulltext;
            let countResults
            if (resultsAreEvents) {
                this.data4pagination = serverData.events;
                countResults = serverData.events.total;
                this.list = serverData.events.data;
                this.isLoading = false;
            } else {
                this.data4pagination = serverData.texts;
                countResults = serverData.texts.total;
                this.list = serverData.texts.data;
                this.isLoading = false;
            }
            this.countResultsString = countResults + ' results';
            if (!countResults)
                this.searchStatus = (!this.userData.accessibleEvents && !this.userData.accessibleMediums)
                                  ? 'NO_RIGHTS' : 'NO_RESULTS';
            else
                this.searchStatus = 'RESULTS';
        },
        setPage: function(pageNr) {
            if (this.isLoading) return;
            this.currentPage = pageNr;
            this.loadResults();
        },
        openMediumOverlay: function(mediumId) {
            this.prepareOpenOverlay();
            this.mediumId4Overlay = mediumId;
            this.highlighted = this.searchData.searchStringFulltext;
            this.overlayType = 'medium';
            for (let i=0; i<this.list.length; i++) {
                // look for snippets in list
                if (this.list[i].medium_id==mediumId) {
                    // if snippets = ['ab,cd','e f']  setup highlighted = ES$:abcd,ef
                    let highlightPurged = [];
                    this.list[i].highlighted.forEach(function(line) {
                        highlightPurged.push(line.replace(/\<\/?em\>/g, '').replace(/[^a-zA-Z]/g, ''));
                    });
                    this.highlighted = 'ES$:' + highlightPurged.join(',');
                    break;
                }
            }
        },
        openVersionOverlay: function(versionData) {
            this.prepareOpenOverlay();
            this.versionID4Overlay = versionData.versionId;
            this.versionEventID4Overlay = versionData.eventId;
            this.overlayType = 'version';
        },
        openCommentsOverlay: function(eventId) {
            this.prepareOpenOverlay();
            this.versionEventID4Overlay = eventId;
            this.overlayType = 'comments';
        },
        openDanielView: function(idData, danieldControlName) {
            this.danielEventId     = '';
            this.danieVersionId    = '';
            this.danielMediumId    = '';
            this.danielDefaultLang = '';
            this.danielVisibleView = danieldControlName;
            switch(danieldControlName) {
                case 'EventEdit':       this.danielEventId       = idData;           break;
                case 'VersionAdd':      this.danielEventId       = idData;           break;
                case 'VersionEdit':     this.danielEventId       = idData.eventId;
                                        this.danielVersionId     = idData.versionId; break;
                case 'MediumAdd':       this.danielEventId       = idData.eventId;
                                        this.danielVersionId     = idData.versionId; break;
                case 'MediumEdit':      this.danielEventId       = this.getEventIdForMedium(idData);
                                        this.danielMediumId      = idData;           break;
                case 'TranslationAdd':  this.danielEventId       = idData.eventId;   this.danielDefaultLang=idData.lang; break;
                case 'TranslationEdit': this.danielEventId       = idData.eventId;
                                        this.danielTranslationId = idData.translationId; break;
            }
        },
        closeOverlay: function() {
            this.mediumId4Overlay = '';
            this.eventID4Overlay = '';
            this.versionID4Overlay = '';
            this.versionEventID4Overlay = '';
//            this.helpUrl4Overlay = '';
            window.scrollTo(0, this.scrollPosition);
            this.overlayType = '';
        },
        closeDanielOverlay: function(somethingChanged) {
            const that = this;
            if (somethingChanged) {
                let indexToReload = -1;
                this.list.forEach(function(item,idx) {
                    if (item.event_id==that.danielEventId)
                        indexToReload = idx;
                });
                if (indexToReload>=0) {
                    const eventId = this.list[indexToReload].event_id;
                    this.list[indexToReload].event_id = '';
                    setTimeout(function() {
                        that.list[indexToReload].event_id = eventId;
                    }, 200);
                }
            }
            this.mediumId4Overlay = '';
            this.eventID4Overlay = '';
            this.versionID4Overlay = '';
            this.danielVisibleView = '';
            window.scrollTo(0, this.scrollPosition);
            // maybe, we came from another open overlay
            this.closeOverlay();
        },
        prepareOpenOverlay: function() {
            this.mediumId4Overlay = '';
            this.eventID4Overlay = '';
            this.versionID4Overlay = '';
            this.versionEventID4Overlay = '';
//            this.helpUrl4Overlay = '';
            this.scrollPosition = window.scrollY;
        },
        addEvent: function() {
            this.danielVisibleView = 'EventAdd';
        },
        getEventIdForMedium: function(mediumId) {
            // TODO
            // das haben wir nicht :=(
            // Folge: Nach Loeschen eines Mediums bleibt dieses noch bestehen, bis man den Event auf- und zuklappt
            return '';
        },
        showHelp: function(helpUrl) {
            window.open(helpUrl, 'help');
//            this.helpUrl4Overlay = helpUrl;
//            this.overlayType = 'help';
        }
    },
    components: {
        cmpSearchDashboard: CmpSearchDashboard,
        cmpSearchResultBlock: CmpSearchResultBlock,
        pagination: LaravelVuePagination,
        cmpCtrlMedium: CmpCtrlMedium,
        cmpCtrlEvent: CmpCtrlEvent,
        cmpCtrlVersion: CmpCtrlVersion,
        cmpCtrlOverlayForNestedData: CmpCtrlOverlayForNestedData,
        cmpCtrlOverlayForDanielsViews: CmpCtrlOverlayForDanielsViews
    }
}
</script>
