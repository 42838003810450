// some default values and configuration values for the kentenich database

// full URL to the application server
export const serverUrl = 'https://jk-backend.kentenich.cloud';
//export const serverUrl = 'https://jk-backend.danielborkovec.cz';

// flag for writing server communication to browser console for debugging purpose
export const logServerCommunication = true;



/*  old stuff
    
// default login data
export const defaultLoginEmail = 'daniel.borkovec@schoenstatt-patres.org';
export const defaultLoginPassword = '';

// full URL to the help document
export const helpUrl = 'https://drive.google.com/drive/u/4/folders/1wET0GVizc0Lo9bXT3tZtwg76UDETeG1r'

*/

