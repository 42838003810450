<!--
View for registration of a new user
-->
<template>
    <div class="register">
        <div v-if="!showConfirmation" class="row justify-content-center align-items-center">
            <div class="col-md-6">
                <div class="col-md-12 border border-danger">
                    <p>
                        <u>Achtung:</u><br/>
                        Dies ist eine interne Datenbank.<br/>
                        Als Anwender versichere ich, dass ich weder meine Zugangsdaten, noch Daten aus der Datenbank an einen Dritten weitergebe.<br/>
                    </p><p>
                        <input type="checkbox" v-model="disclaimerConfirmed" /> Ich bestätige dies.
                    </p>
                </div>
            </div>
        </div>
        <div v-if="!showConfirmation" class="row justify-content-center align-items-center">
            <div class="col-md-6">
                <div class="col-md-12" :style="{opacity:disclaimerConfirmed?1:0.5}">
                    <h3 class="text-center">Register</h3>
                    <label for="register-user-name">User name:</label><br>
                    <input :readonly="!disclaimerConfirmed" placeholder="unique user name" v-model="userName" type="text" id="register-user-name" class="form-control">
                    <b-alert v-if="userNameError" show variant="danger">{{userNameError}}</b-alert>
                    <label for="register-email">Email:</label><br>
                    <input :readonly="!disclaimerConfirmed" placeholder="email" v-model="email" type="text" id="register-email" class="form-control">
                    <b-alert v-if="emailError" show variant="danger">{{emailError}}</b-alert>
                    <label for="register-password">Password:</label><br>
                    <input :readonly="!disclaimerConfirmed" placeholder="password at least 8 characters" v-model="password" type="password" id="register-password" class="form-control">
                    <b-alert v-if="passwordError" show variant="danger">{{passwordError}}</b-alert>
                    <label for="register-password-confirm">Confirm Password:</label><br>
                    <input :readonly="!disclaimerConfirmed" placeholder="confirm password" v-model="passwordConfirm" type="password" id="register-password-confirm" class="form-control">
                    <b-alert v-if="passwordConfirmError" show variant="danger">{{passwordConfirmError}}</b-alert>
                    <label for="register-email">First Name:</label><br>
                    <input :readonly="!disclaimerConfirmed" placeholder="first name" v-model="firstName" type="text" id="register-first-name" class="form-control">
                    <b-alert v-if="firstNameError" show variant="danger">{{firstNameError}}</b-alert>
                    <label for="register-email">Last Name:</label><br>
                    <input :readonly="!disclaimerConfirmed" placeholder="last name" v-model="lastName" type="text" id="register-last-name" class="form-control">
                    <b-alert v-if="lastNameError" show variant="danger">{{lastNameError}}</b-alert>
                    <label for="register-display-name">Display Name:</label><br>
                    <input :readonly="!disclaimerConfirmed" placeholder="prename surname" v-model="displayName" type="text" id="register-display-name" class="form-control">
                    <input :disabled="!disclaimerConfirmed" type="button" v-on:click="register" class="btn btn-secondary" value="Register">
                </div>
            </div>
        </div>
        <div v-if="showConfirmation" class="row justify-content-center align-items-center">
            <div class="col-md-6">
                <div class="col-md-12" :style="{opacity:disclaimerConfirmed?1:0.5}">
                    <h3>Registrierung erfolgreich</h3>
                    <p>
                        Ihr Account wurde angelegt, Sie müssen die Registrierung aber noch bestätigen.<br/>
                        Schauen Sie bitte in Ihre Emails und drücken dort auf den Link.
                    </p><p>
                        Damit Sie mit der Datenbank arbeiten können, muss der Administrator 
                        Ihnen noch die passenden Benutzerrechte zuteilen.<br/>
                        Warten Sie bitte auf dessen Bestätigung per Email.
                    </p>
            </div>
            </div>
        </div>
    </div>
</template>
<style>
</style>
<script>

import SrvServerAccess from '../global/SrvServerAccess';
import SrvMessages from '../global/SrvMessages';

export default {
    data: function() {
        return {
            disclaimerConfirmed: false,
            userName: '',
            userNameError: '',
            email: '',
            emailError: '',
            password: '',
            passwordError: '',
            passwordConfirm: '',
            passwordConfirmError: '',
            firstName: '',
            firstNameError: '',
            lastName: '',
            lastNameError: '',
            displayName: '',
            showConfirmation: false
        };
    },  
    methods: {
        register: function () {
            this.userNameError = '';
            this.emailError = '';
            this.passwordError = '';
            this.passwordConfirmError = '';
            this.firstNameError = '';
            this.lastNameError = '';
            if (! this.userName)
                this.userNameError = 'Bitte einen Benutzernamen angeben';
            if (! this.email)
                this.emailError = 'Bitte eine gültige Email-Adresse angeben';
            if (! this.password)
                this.passwordError = 'Bitte ein Passwort angeben';
            if (this.password!=this.passwordConfirm)
                this.passwordConfirmError = 'Die beiden Passwörter stimmen nicht überein';
            if (! this.firstName)
                this.firstNameError = 'Bitte den Vornamen angeben';
            if (! this.lastName)
                this.lastNameError = 'Bitte den Familiennamen angeben';
            if (! this.displayName)
                this.displayName = this.firstName + ' ' + this.lastName;
            if (  this.userNameError  || this.emailError
                ||this.passwordError  || this.passwordConfirmError
                ||this.firstNameError || this.lastNameError)
                return;
            const that = this;
            SrvServerAccess.request('POST','/api/register', {
                    email: this.email,
                    username: this.userName,
                    first_name: this.firstName,
                    last_name: this.lastName,
                    display_name: this.displayName,
                    password: this.password,
                    password_confirmation: this.passwordConfirm
            },true).then(
                // Success
                function(serverResponse) {
                    that.showConfirmation = true;
                    if (serverResponse && serverResponse.data && serverResponse.data.message)
                        SrvMessages.info(serverResponse.data.message);
                    else {
                        SrvMessages.info('Account wurde angelegt. Benutzerrechte bekommen Sie demnächst zugeteilt.');
                    }
                    setTimeout(function(){
                        that.$router.push('/login').catch(function(){});                        
                    },16000);
                },
                // Error
                function(error) {
                    if (  error.response.data && error.response.data.errors 
                        &&(  error.response.data.errors.name
                           ||error.response.data.errors.email
                           ||error.response.data.errors.password)) {
                        if (error.response.data.errors.name instanceof Array)
                            that.userNameError = error.response.data.errors.name.join('\n');
                        if (error.response.data.errors.email instanceof Array)
                            that.emailError = error.response.data.errors.email.join('\n');
                        if (error.response.data.errors.password instanceof Array)
                              that.passwordError = error.response.data.errors.password.join('\n');
                        if (error.response.data.errors.name instanceof Array)
                              that.firstNameError = error.response.data.errors.name.join('\n');
                        // fuer first_name, last_name und display_name kommt er mit einer Error-Message auf "name" hier an
                        // aber der Code wird gar nie erreicht, da ich clientseitig das ja alles vorher schon abfange.
                    } else {
                        let message = error;
                        while(message && (typeof(message)=='object'))
                            for(let key in message) {
                                message = message[key];
                                break;
                            }
                        SrvMessages.error(message);
                    }
                }
            );
        }
    }
}               
</script>

