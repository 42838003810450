<!--
View  after login for users with no rights
-->
<template>
  <div class="version">
    <h2>Willkommen bei der</h2>
    <h1>Kentenich-Datenbank</h1>
  </div>    
</template>
<style>
</style>
<script>
export default {
    data: function() {
        return  {
        };
    },
    methods: {
    }
}
</script>
